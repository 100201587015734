import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Button} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

import Dropzone from './Dropzone';
import Progress from './Progress';
import { API } from '../_helpers/call_backend';
import { allowUrlActions } from "../_actions";

class Upload extends Component{
    constructor(props){
        super(props);
        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false
        };
    }

    onFilesAdded = (files) => {
        this.setState(prevState => ({
          files: prevState.files.concat(files)
        }));
    }

    renderProgress = (file) => {
        const uploadProgress = this.state.uploadProgress[file.name];
        if (this.state.uploading || this.state.successfullUploaded) {
          return (
            <div className="ProgressWrapper">
              <Progress progress={uploadProgress ? uploadProgress.percentage : 0}/>
              <img
                 style={{marginRight: 10}} alt="done"
                src={require("../img/outline-check_circle-24px.svg")}
                style={{ opacity: uploadProgress && uploadProgress.state === "done" ? 0.5 : 0, marginLeft: 10 }}
              />
            </div>
          );
        }
    }

    renderActions = () =>  {
        if (this.state.successfullUploaded) {
          return (
            <Button variant="contained" className="Button"
                onClick={() =>
                  this.setState({ files: [], successfullUploaded: false })
                }
            >
                Clear
                <DeleteIcon className="Delete-icon"/>
            </Button>
          );
        } else {
          return (
            <div>
                <Button variant="contained" className="Button"
                    onClick={() =>
                      this.setState({ files: [], successfullUploaded: false })
                    }
                >
                    Clear
                    <DeleteIcon className="Delete-icon"/>
                </Button>
                <Button variant="contained" className="Button" disabled={this.state.files.length < 0 || this.state.uploading}
                    onClick={this.uploadFiles}>
                    Upload
                    <CloudUploadIcon className="Upload-icon" />
                </Button>
            </div>
          );
        }
    }
    
    uploadFiles = async () => {
        this.setState({ uploadProgress: {}, uploading: true });
        const promises = [];
        this.state.files.forEach(file => {
          promises.push(this.sendRequest(file));
        });
        try {
          await Promise.all(promises).then(() => {
              const {dispatch} = this.props;
              dispatch(allowUrlActions.getAllUrl());
          });
          this.setState({ successfullUploaded: true, uploading: false });
        } catch (e) {
          // Not Production ready! Do some error handling here instead...
          this.setState({ successfullUploaded: true, uploading: false });
        }
    }

    sendRequest(file) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest();
          
          req.upload.addEventListener("progress", event => {
            if (event.lengthComputable) {
             const copy = { ...this.state.uploadProgress };
             copy[file.name] = {
              state: "pending",
              percentage: (event.loaded / event.total) * 100
             };
             this.setState({ uploadProgress: copy });
            }
          });
            
           req.upload.addEventListener("load", event => {
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "done", percentage: 100 };
                this.setState({ uploadProgress: copy });
                resolve(req.response);
           });
            
           req.upload.addEventListener("error", event => {
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "error", percentage: 0 };
                this.setState({ uploadProgress: copy });
                reject(req.response);
           });
          const formData = new FormData();
          formData.append("file", file, file.name);
      
          req.open("POST", API.UPLOAD);
          req.send(formData);
        });
    }

    render(){
        return (
            <div className="Upload">
                <div className="Content">
                    <div>
                        <Dropzone
                          onFilesAdded={this.onFilesAdded}
                          disabled={this.state.uploading || this.state.successfullUploaded}
                        />
                    </div>
                </div>
                <div className="Files">
                    {this.state.files.map(file => {
                        return (
                            <div key={file.name} className="Row">
                                <span className="Filename">{file.name}</span>
                                {this.renderProgress(file)}
                            </div>
                        );
                    })}
                </div>
                <div className="Actions">{this.renderActions()}</div>
            </div>
        );
    }
}


export default connect(null, null)(Upload);