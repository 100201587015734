import React from 'react';
import { connect } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { chkSessionActions, menuActions } from "../_actions"

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    setMenuState = (type) => {
        const { dispatch } = this.props;
        dispatch(menuActions.setMenu(type));
    }

    componentWillMount() {
        this.setMenuState('About');
    }

    componentWillReceiveProps(nextProps) {
    }

    render() {
        return (
            <div>
                <section class="about">

                </section>
                <section class="about-cnt">
                  <div class="container">
                      <div class="row">
                          <div class="col-md-12 about-head">
                              <h1>SearchLight Minimizes Your Digital Risk</h1>
                              <p>Reducing digital risks requires a focus on three pillars: the need to detect data loss,
                                  <br /> the need to secure brands online, and reduce your attack surface.</p>
                          </div>

                          <div class="col-md-7 about-lft">
                              <ul>
                                  <li>
                                      <span class="spn-one"><img src="https://www.digitalshadows.com/uploads/2017/10/dataloss.png" alt="data loss" class="img-responsive"/></span>
                                      <span class="spn-two">
            <h3>Data Loss</h3>
            <p>Act quickly to remove exposed sensitive data</p>
            <a href="#">READ MORE</a>
            </span>
                                      <div class="clearfix"></div>
                                  </li>

                                  <li>
                                      <span class="spn-one"><img src="https://www.digitalshadows.com/uploads/2017/10/brand_exposure.png" alt="brand_exposure" class="img-responsive"/></span>
                                      <span class="spn-two">
            <h3>Online Brand Security</h3>
            <p>Protect your brand and reputation</p>
            <a href="#">READ MORE</a>
            </span>
                                      <div class="clearfix"></div>
                                  </li>

                                  <li>
                                      <span class="spn-one"><img src="https://www.digitalshadows.com/uploads/2017/10/infrastructureexposure.png" alt="infrastructureexposure" class="img-responsive"/></span>
                                      <span class="spn-two">
            <h3>Attack Surface </h3>
            <p>Identify weak spots in your infrastructure</p>
            <a href="#">READ MORE</a>
            </span>
                                      <div class="clearfix"></div>
                                  </li>

                              </ul>
                          </div>

                          <div class="col-md-5 about-ryt">
                              <img src="images/about-ryt.jpg" class="img-fluid"/>
                          </div>

                      </div>
                  </div>
              </section>

              <section class="howitwork">
                  <div class="container">
                      <div class="row">

                          <div class="col-md-6 works">
                              <img src="images/works.jpg" class="img-fluid"/>
                          </div>

                          <div class="col-md-6 how-head">
                              <h1>How it Works</h1>
                              <p>SearchLight is comprised of four main stages, which are outlined below. At each stage, we act as an extension of your team to help configure SearchLight, collect from hard-to-reach sources, add analysis of threat intelligence, and provide recommended actions.</p>

                          </div>
                      </div>
                  </div>
              </section>

              <section class="footer">
                  <div class="container">
                      <div class="col-md-12 footer-head">
                          <h4>Connect with us</h4>
                          <ul>
                              <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                              <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                              <li><i class="fa fa-instagram" aria-hidden="true"></i></li>
                              <li><i class="fa fa-youtube-play" aria-hidden="true"></i></li>
                          </ul>
                          <p>Copyright Threathaus 2019. All Rights Reserved.</p>
                      </div>
                  </div>
              </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registration } = state;
    return {
        registration,
    };
}

export default connect(mapStateToProps, null, null, { pure: false })(About);