const SERVER_URL = 'https://threathaus.com';
// const SERVER_URL = 'http://localhost:3000';

export const API = {
    LOGIN: SERVER_URL + '/api/authentication',
    LOGOUT: SERVER_URL + '/api/authentication/logout',
    SUBMITURL: SERVER_URL + '/api/malware',
    ALLOWURL: SERVER_URL + '/api/admin/addNewMalware',
    DELETEURL: SERVER_URL + '/api/admin/malwareDelete',
    GETALLURL: SERVER_URL + '/api/admin/malwares',
    GET_ALL_APPROVAL_MALWARE: SERVER_URL + '/api/asn',
    CHECKSESSION: SERVER_URL + '/api/authentication/check-session',
    UPLOAD: SERVER_URL + '/api/admin/uploadCsv',
    ADMIN_URL: '/45Ttg^RKiPJ9U2P',
    GET_ALL_USER: SERVER_URL + '/api/admin/getAllModerateUser',
    CREATE_USER: SERVER_URL + '/api/admin/addAdminUser',
    UPDATE_USER: SERVER_URL + '/api/admin/updateAdminUser',
    DELETE_USER: SERVER_URL + '/api/admin/deleteAdmin',
    CHECKADMIN: SERVER_URL + '/api/admin/checkAdminUser',
    CHECKSESSIONADMIN: SERVER_URL + '/api/admin/checkAdminUser',
    GENERATECOUNTRY: SERVER_URL + '/api/feeds/getFeedsByCountry',
    CONTACT_US_SUBMIT: SERVER_URL + '/api/contact/send-email',
    NOTIFY_EMAIL_SUBMIT: SERVER_URL + '/api/notify/notify-email',

}