import { toast } from "react-toastify";
import { userService } from '../_services/';
import { API } from '../_helpers';
export const allowUrlActions = {
    allowUrl,
    deleteUrl,
    getAllUrl,
    getAllMalwaresInfo
};

function getAllUrl(action){
    return dispatch => {
        let apiEndPoint = API.GETALLURL;
        userService.get(apiEndPoint)
            .then( response => {
                if(response.status === 200){
                    dispatch(showAllUrl(response.data));
                }else{
                    showMessage(response.data.message);
                }
            }).catch(e=> {
                showMessage(e.message);
            });
    }
}

function deleteUrl(action){
    return dispatch => {
        let apiEndPoint = `${API.DELETEURL}/${action.id}`;
        userService.deleteDetail(apiEndPoint)
            .then(response => {
                if (response.status === 200) {
                    showMessage(response.data.message);
                    dispatch(setDeleteUrl(response.data.success_id));
                }else{
                    showMessage(response.data.message);
                }
            }).catch(e=>{
                showMessage(e.message);
            });
    }
}

function allowUrl(action) {
    return dispatch => {
        let apiEndPoint = API.ALLOWURL;
        dispatch(sendingAllowRequest(action.id));
        userService.post(apiEndPoint, action)
            .then(response => {
                if (response.status === 200) {
                    showMessage(response.data.message);
                    dispatch(setAllowUrl(response.data));
                }else{
                    dispatch(setAllowFaile(action.id));
                }
            }).catch(e=>{
                showMessage(e.message);
            });
    }
}

function getAllMalwaresInfo(action) {
    return dispatch => {
        let apiEndPoint = API.GET_ALL_APPROVAL_MALWARE;
        userService.get(apiEndPoint)
            .then( response => {
                if(response.status === 200){
                    dispatch(showAllApprovalMalwares(response.data));
                }else{
                    showMessage(response.data.message);
                }
            }).catch(e=> {
                showMessage(e.message);
            });
    }
}

function showMessage(message){
    toast.success(message, {
        position: toast.POSITION.TOP_CENTER
    });
}
export function sendingAllowRequest(id) {
    return {
        type: "CHANGINE_ALLOW_STATE",
        payload: id
    }
}
export function setAllowFaile(id) {
    return {
        type: "CHANGINE_ALLOW_FAILE",
        payload: id
    }
}
export function setAllowUrl(allowed) {
    return {
        type: "GET_ALLOW_STATE",
        payload: allowed
    }
}
export function setDeleteUrl(deleted){
    return {
        type: "GET_DELETE_STATE",
        payload: deleted
    }
}
export function showAllUrl(data){
    return{
        type: "GET_ALL_URL",
        payload: data
    }
}

export function showAllApprovalMalwares(data){
    return{
        type: "GET_ALL_APPROVAL_MALWARES",
        payload: data
    }
}