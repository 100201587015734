import React from 'react';
import clsx from 'clsx';
import { Grid, TextField, Button, Typography, Paper} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import ReactCountryFlag from "react-country-flag";
import {countries} from 'country-data';
import { submitUrlActions } from '../_actions';
import { connect } from 'react-redux';
import {history} from '../_helpers';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el, index) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'dataAdded', align: 'center', width: '*', disablePadding: true, label: 'Dataadded(UTC)' },
  { id: 'url_ip', align: 'center', width: '20%', disablePadding: false, label: 'URL/IP' },
  { id: 'status', align: 'center', width: '9%', disablePadding: true, label: 'Status' },
  { id: 'tag', align: 'center', width: '*', disablePadding: true, label: 'Tags' },
  { id: 'ip', align: 'center', width: '*', disablePadding: false, label: 'IP' },
  { id: 'reporter', align: 'center', width: '*', disablePadding: false, label: 'Reporter' },
  { id: 'country', align: 'center', width: '*', disablePadding: true, label: 'Country' },
  { id: 'virusTotal', align: 'center', width: '20%', disablePadding: false, label: 'Scan with VirusTotal' },
  { id: 'ASN',align: 'center', width: '9%', disablePadding: true, label: 'As Number' },
  { id: 'domain_registrar', align: 'center', width: '9%', disablePadding: true, label: 'Domain Registrar' },
  { id: 'domain_ip_owner', align: 'center', width: '9%', disablePadding: true, label: 'Domain/IP Owner' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    
    <TableHead>
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            xs={2}
            style={{width:row.width}}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.8),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const {onResetFilter} = props;
  const {onSearchFilter} = props;
  const [searchUrl, setSearchUrl] = React.useState('');

  const requestResetFilter = event =>{
    onResetFilter(event);
  }

  const _handleKeyDown = event => {
    if(event.key === 'Enter'){
      requestSearchFilter();
    }
  }
  const onChange = event => {
    setSearchUrl(event.target.value);
  }

  const requestSearchFilter = event => {
    onSearchFilter(searchUrl);
  }

  return (
    <Toolbar
      className={clsx(classes.root, classes.highlight)}
    >
      <div className={classes.title}>
          <Typography variant="h6" id="tableTitle" />
      </div>
      <div className={classes.spacer}>
          <Grid container spacing={1} direction="row" justify="left" alignItems="left" className="rowpan">
            <Grid item xs={9} className="search-input">
                <TextField placeholder="domain, url etc" onChange={onChange} onKeyDown={_handleKeyDown} 
                    id="bootstrap-input" style={{ width: "100%" }} variant="outlined" />
            </Grid>
            <Grid item xs = {1} />
            <Grid item xs={2}  style={{marginTop:10}}>
                <Button variant="contained" onClick={requestSearchFilter} className="search-btn">
                    Search
                </Button>
            </Grid>
          </Grid>
          
      </div>
      <div className={classes.actions}>
        <Tooltip title="Reset Filter" onClick = {requestResetFilter}>
            <IconButton aria-label="Reset Filter">
              <ThreeSixtyIcon />
            </IconButton>
        </Tooltip>
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    border: 1 ,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

const EnhancedTable = (props) => {
  const rows = props.rows || [];
  
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('dataAdded');
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterData, setFilterData] = React.useState(null);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  // function extractHostname(url) {
  //   var element = document.createElement("a");
  //   element.href = url;
  //   return element.hostname;
  // }

  const customFilter = (key, data) => {

    const aryFilter = rows.filter( row => {
      if(key === 'url_ip'){
        let check_flag = ( row['url_ip'] && row['url_ip'].indexOf(data) > -1 );
        check_flag = check_flag || ( row['status'] && row['status'].indexOf(data) > -1 );
        check_flag = check_flag || ( row['tag'] && row['tag'].indexOf(data) > -1 );
        check_flag = check_flag || ( row['ip'] && row['ip'].indexOf(data) > -1 );
        check_flag = check_flag || ( row['domain'] && row['domain'].indexOf(data) > -1 );
        check_flag = check_flag || ( row['reporter'] && row['reporter'].indexOf(data) > -1 );
        if(row.country){
          let country_name = countries[(row.country).toLocaleUpperCase()].name;
          check_flag = check_flag || ( row['country'].indexOf(data) > -1 );
          check_flag = check_flag || ( country_name.indexOf(data) > -1 );
        }
        check_flag = check_flag || ( row['ASN'] && row['ASN'].indexOf(data) > -1 );
        check_flag = check_flag || ( row['virusTotal'] && row['virusTotal'].indexOf(data) > -1 );
        check_flag = check_flag || ( row['domain_registrar'] && row['domain_registrar'].indexOf(data) > -1 );
        check_flag = check_flag || ( row['domain_ip_owner'] && row['domain_ip_owner'].indexOf(data) > -1 );
        return check_flag;
      }
      else{
        return row[key] === data;
      }
    });
    return aryFilter;
  }

  const filteredRows = filterData ? customFilter(filterData.key, filterData.value) : rows;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

  const setDetailsData = (row) => {
    const {dispatch} = props;
    dispatch(submitUrlActions.detailsData(row));
    history.push('/details');
  }

  const goScanVirusTotal = (url) => {
    window.open(url, '_blank');
  }

  const restrict = (url) => {
    if(url.length > 50){
      return url.substring(0, 47) + "...";
    }else
      return url;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar onResetFilter={() => setFilterData(null)} onSearchFilter={(data) => setFilterData({key: 'url_ip', value: data})}/>
        <div className={classes.tableWrapper}>
          <Table style={{ width: "auto", tableLayout: "auto" }}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <colgroup>
              <col style={{width:'5%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'5%'}}/>
              <col style={{width:'*'}}/>
              <col style={{width:'*'}}/>
              <col style={{width:'*'}}/>
              <col style={{width:'*'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'9%'}}/>
              <col style={{width:'9%'}}/>
              <col style={{width:'9%'}}/>
          </colgroup>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
            />
            <TableBody>
              {stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow style={{cursor:'pointer'}} key={index}>
                      <TableCell align="left" onClick={() => setFilterData({key: 'dataAdded', value: row.dataAdded})} >{row.dataAdded}</TableCell>
                      <TableCell align="center" onClick={() => setDetailsData(row)} >{restrict(row.url_ip)}</TableCell>
                      <TableCell align="left" padding='none' onClick={() => setFilterData({key: 'status', value: row.status})} >
                        <span className={(row.status === "online") ? "online": "offline"}>{row.status}</span>
                      </TableCell>
                      <TableCell align="left" padding='none' onClick={() => setFilterData({key: 'tag', value: row.tag})}>{row.tag}</TableCell>
                      <TableCell align="right" onClick={() => setFilterData({key: 'ip', value: row.ip})}>{row.ip}</TableCell>
                      <TableCell align="left" padding='none' onClick={() => setFilterData({key: 'reporter', value: row.reporter})}>{row.reporter}</TableCell>
                      <TableCell align="left" padding='none' onClick={() => setFilterData({key: 'country', value: row.country || ''})}>
                        <div>
                          {
                            row.country ? <ReactCountryFlag 
                              styleProps={{
                                  width: '20px',
                                  height: '20px'
                              }}
                              code={row.country}
                              svg
                          /> : null
                          }
                        </div>
                        {row.country ? countries[(row.country).toLocaleUpperCase()].name : null}
                      </TableCell>
                      <TableCell align="left" padding='none' className="table-td" onClick={() => goScanVirusTotal(row.virusTotal)}><img src={require('../img/VTotal_.png')} height={30}  /></TableCell>
                      <TableCell align="right" onClick={() => setFilterData({key: 'ASN', value: row.ASN})}>{row.ASN}</TableCell>
                      <TableCell align="left" padding='none' onClick={() => setFilterData({key: 'domain_registrar', value: row.domain_registrar})}>{row.domain_registrar}</TableCell>
                      <TableCell align="left" padding='none' onClick={() => setFilterData({key: 'domain_ip_owner', value: row.domain_ip_owner})}>{row.domain_ip_owner}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default connect(null, null)(EnhancedTable);