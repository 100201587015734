import { userConstants } from '../_constants';

const initialState = {
  login: {},
}
export function registration(state = initialState, action) {
  switch (action.type) {
    case 'SESSION_ALLOW_STATE':
      return {
        ...state,
        login: action.payload
      };
    case "SET_MENU_STATE":
      return {
        ...state,
        menu: action
      };
    case userConstants.REGISTER_FAILURE:
      return {};
    default:
      return state
  }
}