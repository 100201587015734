import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { virus } from './virus.reducer';
import { malwares } from './malwares.reducer';
import { moderator } from './moderator.reducer';


const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  virus,
  malwares,
  moderator
});

export default rootReducer;