import { userService } from '../_services/';
import { API } from '../_helpers';
export const submitActions = {
    submit,
};

function submit(action) {
    return dispatch => {
        let apiEndPoint = API.CONTACT_US_SUBMIT;
        userService.post(apiEndPoint, action)
        .then(response => {
            if (response.status === 200) {
                showMessage(response.data.message);
            }else{
                showMessage(response.data.message);
            }
        }).catch(e=>{
            showMessage(e.message);
        });
    }
}

function showMessage(message){
    alert(message);
// toast.success(message, {
//     position: toast.POSITION.TOP_CENTER
// });
}

export function success(data) {
    return {
        type: "CONTACT_US_SUCCESS",
        payload: data
    }

}

export function failed(data) {
    return {
        type: "CONTACT_US_FAILED",
        payload: data
    }
}
