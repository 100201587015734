import { toast } from "react-toastify";
import { userService } from '../_services/';
import { API } from '../_helpers';
export const submitUrlActions = {
    submitUrl,
    detailsData,
};
function submitUrl(action) {
    return dispatch => {
        let apiEndPoint = API.SUBMITURL;
        userService.post(apiEndPoint, action)
            .then(response => {
                if (response.status === 200) {
                    showMessage(response.data.message);
                    dispatch(setUrlDetails(response.data));
                }else{
                    showMessage(response.data.message);
                }
            }).catch(e=>{
                showMessage(e.message);
            });
    }
}

function detailsData(action){
    return dispatch => {
        dispatch(sendUrlDetails(action));
    }
}

function showMessage(message){
        alert(message);
    // toast.success(message, {
    //     position: toast.POSITION.TOP_CENTER
    // });
}
export function setUrlDetails(virus) {
    return {
        type: "SUBMIT_GET_VIRUS",
        payload: virus
    }
}

export function sendUrlDetails(data){
    return {
        type: "SEND_URLDETAILS",
        payload: data
    }
}