import React from 'react';
import { connect } from 'react-redux';
import { Paper, Grid, Typography } from '@material-ui/core';
import { TextField, Button } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons';
import {userActions} from '../_actions';
import {chkSessionActions} from '../_actions/checkSession.action';
import { history } from '../_helpers/history';

class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loginstate: false,
            username: '',
            password: '',
        }
    }
    goLogin = (type) => {
        const { dispatch } = this.props;
        dispatch(userActions.login(this.state));
    }
    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(chkSessionActions.checkSession());
    }
    componentWillReceiveProps(nextProps) {
        let dashboard_url = "/1uczlsUen8amKIkWm3054YIAbsbrEvatPcTYtD2Gz2Q9yYTKRJDJxcawsbNWwOf9OWX7nPBNGiK46uDM16lp8jJqllaHXgFz0sve6WPC4puZJXzzUIMQSl53u5yh93H0k22gMHIFW0suMRTwnva5E5WZ1j40cBOmrGs2dYxYNPipu1JHkJBMFnBwwwkxVZHbtY3xMqjn3rCngbUoJj0exsyRMGevtMwNAp8aDhowwOJCOsQlBEpWULEwfGSzgccG";
        if (nextProps.registration.login.status) {
            history.push(dashboard_url);
        }
    }
    render() {
        return (
            <Paper className="login-panel">
                <Grid container spacing={1} direction="row" justify="center" alignItems="center" className="rowpan">
                    <Grid item xs={11} style={{ marginTop: 50, marginBottom: 20 }}>
                        <Typography className="title" style={{ textAlign: 'center', marginBottom: 20 }} variant="h4" color="inherit" noWrap>
                            Admin Login
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={1} />
                    <Grid item xs={2} className="login-icon">
                        <Face color="primary" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField id="username" label="Username" type="email" fullWidth autoFocus required onChange={this.handleChange} />
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={1} />
                    <Grid item xs={2} className="login-icon">
                        <Fingerprint color="primary" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField id="password" label="Password" type="password" fullWidth required onChange={this.handleChange} />
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
                <Grid container justify="center" style={{ marginTop: '50px', paddingBottom: '40px' }}>
                    <Grid item xs = {8}>
                        <Button variant="contained" color="primary" fullWidth={true} onClick={this.goLogin}>
                            Sign in
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}
const mapStateToProps = (state) => {
    const { users, registration } = state;
    return {
        loginstate: users.loginstate,
        registration
    }
}

export default connect(mapStateToProps, null, null, { pure: false })(Login);