import { userConstants } from '../_constants';
import { userService } from '../_services';
import { history } from '../_helpers';
import { API } from '../_helpers';
import { chkSessionActions } from '../_actions/checkSession.action';

export const userActions = {
    login,
    logout,
    register,
    getAll,
    delete: _delete
};

function login(action) {
    return dispatch => {
        let apiEndPoint = API.CHECKADMIN;
        userService.post(apiEndPoint, action)
            .then(response => {
                dispatch(chkSessionActions.checkSession());
            }).catch(e => {
                // showMessage(e.message);
            });
    }

}

function logout() {
    return dispatch => {
        let apiEndpoint = API.LOGOUT;
        userService.get(apiEndpoint)    
            .then(response => {
                history.push('/');
            }).catch(error => {

            });
    }
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}