import React from 'react';
import { connect } from 'react-redux';
import {Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {chkSessionActions} from '../_actions/checkSession.action';

import Admin from './admin';
import Users from './Users';
import { history } from '../_helpers/history';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openWindow: '1',
    }
  }

  checkSession = () =>{
    const { dispatch } = this.props;
    dispatch(chkSessionActions.checkSession());
  }

  componentDidMount() {
    this.checkSession();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.registration.login.status) {
      history.push('/Home');
    }
  }
  goMalware = () => {
    this.setState({
      openWindow: '1',
    });
  }

  goUsers = () => {
    this.setState({
      openWindow: '2',
    });
  }

  render() {

    return (
      <div>
        <Grid container spacing={1} direction="row" className="rowpan">
          <Grid item xs={3} className='sidebar' >
            {
              this.props.registration.login.status ? 
                <Typography className="title" variant="h6" color="inherit" noWrap>
                  <a href="javascript:void(0)" onClick={() => this.goMalware()}>Malware</a>
                </Typography> : null
            }
            {
              this.props.registration.login.status === 'super' ? 
                <Typography className="title" variant="h6" color="inherit" noWrap>
                  <a href="javascript:void(0)" onClick={() => this.goUsers()}>Users</a>
                </Typography> : null
            }
          </Grid>
          <Grid item xs={9} >
            {
              this.state.openWindow === '1' ? <Admin /> : null
            }
            {
              this.state.openWindow === '2' ? <Users /> : null
            }
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registration } = state;
  return {
      registration
  };
}

export default connect(mapStateToProps, null, null, { pure: false })(Dashboard);