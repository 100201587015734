import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { userActions } from '../_actions';
// import '../theme/navbar.css'
import { red } from '@material-ui/core/colors';

function PrimarySearchAppBar(props) {
  const [currentMenu, setCurrentMenu] = useState(props.registration);
  const [sticky, setSticky] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

    
  const handleScroll = () => {
    let lastScrollY = window.scrollY;

    if(lastScrollY >= 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };


  if (props.registration.menu) {
    if (currentMenu !== props.registration.menu.payload)
      setCurrentMenu(props.registration.menu.payload);   
  }

  function goLogout() {
    const { dispatch } = props;
    dispatch(userActions.logout());
  }

  function chkCurrent(str) {
    if (currentMenu) {
      if (currentMenu === str)
        return 'current-menu'
    }
    return '';
  }

  return (
    <div>
        <section className="top-icn">
          <div className="container">
              <div className="row">

                  <div className="col-md-6 ryt-top-icn">
                      <ul>
                          <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                          <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                          <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                          <li><a href="#"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                      </ul>
                  </div>

                  <div className="col-md-6 left-top-icn">
                      <ul>
                          <li><a href="#"><i className="fa fa-envelope" aria-hidden="true"></i>Hello@example.com</a></li>
                          <li><a href="#"><i className="fa fa-phone" aria-hidden="true"></i>+123456</a></li>
                      </ul>
                  </div>

              </div>
          </div>
      </section>

      <nav className={sticky ? 'navbar navbar-expand-lg menu sticky' : 'navbar navbar-expand-lg menu'} id="navbar">
        <div className="container">
            <a className="navbar-brand col-md-4 col-6" href="index.html"><img src="images/logo.png" className="img-fluid1" alt="DUMMY ALT TEXT"/>
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" data-interval="1000" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"><i className="fa fa-bars" aria-hidden="true"></i></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a href="home" className={`nav-link ${chkCurrent('Home')}`}>Home </a>
                    </li>

                    <li className="nav-item">
                        <a href="feeds" className={`nav-link ${chkCurrent('Feeds')}`}>Feeds</a>
                    </li>
                    <li className="nav-item">
                        <a href="browse" className={`nav-link ${chkCurrent('Browse')}`}>Browse</a>
                    </li>
                    <li className="nav-item">
                        <a href="about" className={`nav-link ${chkCurrent('About')}`}>About</a>
                    </li>

                    <li className="nav-item">
                        <a href="contact" className={`nav-link ${chkCurrent('Contact')}`}>Contact</a>
                    </li>

                </ul>

            </div>
        </div>
      </nav>
    </div>
  );
}

function mapStateToProps(state) {
  const { registration } = state;
  return {
    registration
  };
}

export default withRouter(connect(mapStateToProps, null, null, { pure: false })(PrimarySearchAppBar));

