import { userService } from '../_services/';
import { API } from '../_helpers';
export const moderatorActions = {
    getAllModerator,
    addModerator,
    updateModerator,
    deleteModerator,
};
function getAllModerator(action) {
    return dispatch => {
        let apiEndPoint = API.GET_ALL_USER;
        userService.get(apiEndPoint, action)
            .then(response => {
                if (response.status === 200) {
                    dispatch(getAllUser(response.data));
                }else{
                }
            }).catch(e=>{
        });
    }
}

function addModerator(action) {
    return dispatch => {
        let apiEndPoint = API.CREATE_USER;
        userService.post(apiEndPoint, action)
            .then(response => {
                if (response.status === 200) {
                    dispatch(addUser(response.data));
                }else{
                }
            }).catch(e=>{
        });
    }
}

function updateModerator(action){
    return dispatch => {
        let apiEndPoint = API.UPDATE_USER;
        userService.put(apiEndPoint, action)
            .then(response => {
                if (response.status === 200) {
                    dispatch(updateUser(response.data));
                }else{
                }
            }).catch(e=>{
        });
    }
}

function deleteModerator(action){
    return dispatch => {
        let apiEndPoint = API.DELETE_USER;
        userService.deleteDetail(apiEndPoint + '/' + action)
            .then(response => {
                if (response.status === 200) {
                    dispatch(deleteUser(action));
                }else{
                }
            }).catch(e=>{
            });
    }
}

export function getAllUser(data) {
    return {
        type: "GET_ALL_USER",
        payload: data
    }

}
export function addUser(data) {
    return {
        type: "ADD_USER",
        payload: data
    }
}

export function updateUser(data){
    return {
        type: "UPDATE_USER",
        payload: data
    }
}

export function deleteUser(data){
    return {
        type: "DELETE_USER",
        payload: data
    }
}