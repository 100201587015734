import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {Table, Grid, Button, TextField} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import { moderatorActions } from "../../_actions"

// rct card box
import RctCollapsibleCard from './RctCollapsibleCard';

// For Basic Table

class BasicTable extends Component {

	constructor(props){
		super(props);
		this.state = {
			open: false,
			delOpen: false,
			data: {_id:'',userName:'',userEmail:'', userPassword:''},
			modal_type: 'create',
		}
	}

	handleClickOpen = (type, data1) => {
		this.setState({
			modal_type: type,
			data: data1,
		});
		this.setState({
			open: true,
		});
	}

	handleClickDelOpen = (data) => {
		this.setState({
			delOpen: true,
			data: data,
		});
	}

	handleClose = () => {
		this.setState({
			open: false
		});
	}

	handleDelClose = () => {
		this.setState({
			delOpen: false
		});
	}

	onChange = e => {
		let data = this.state.data;
		data[e.target.id] = e.target.value;
        this.setState({
           data: data
        });
	};

	getAllUser = () => {
		const { dispatch } = this.props;
		dispatch(moderatorActions.getAllModerator());
	}
	
	goCreate = () => {
		const { dispatch } = this.props;
		let data = {
			userName: this.state.data.userName,
			userEmail: this.state.data.userEmail,
			userPassword: this.state.data.userPassword
		}
		dispatch(moderatorActions.addModerator(data));
		this.setState({
			open: false,
		})
	}

	goUpdate = () => {
		const { dispatch } = this.props;
		let data = {
			_id: this.state.data._id,
			userName: this.state.data.userName,
			userEmail: this.state.data.userEmail,
			userPassword: this.state.data.userPassword
		}
		dispatch(moderatorActions.updateModerator(data));
		this.setState({
			open: false,
		})
	}

	goDelete = () => {
		const { dispatch } = this.props;
		// let data = {
		// 	id: this.state.data._id,
		// }
		dispatch(moderatorActions.deleteModerator(this.state.data._id));
		this.setState({
			delOpen: false,
		})
	}
	componentDidMount() {
		this.getAllUser();
	}

	render() {
		const moderator = this.props.moderator.data1;
		console.log('moderator', moderator);
		return (
			<div className="table-wrapper">
				<Grid container direction="row" justify="center" alignItems="center" style={{paddingBottom: 10}}>
                    <Grid item xs={11} >
						<Button variant="contained" size="small" onClick={() => this.handleClickOpen('create', JSON.parse('{"_id":"","userName":"","userEmail":"","userPassword":""}'))}>
							<AddBoxIcon style={{paddingRight: 10}}/>
							Add User
						</Button>
                    </Grid>
                </Grid>
				<RctCollapsibleCard fullBlock>
					<div className="table-responsive">
						<Table>
							<TableHead>
								<TableRow hover>
									<TableCell>Name</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Password</TableCell>
									<TableCell>Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<Fragment>
									{moderator && moderator.map((employee, key) => (
										<TableRow hover key={key}>
											<TableCell>
												<h5 className="m-0 pt-15">{employee.userName}</h5>
											</TableCell>
											<TableCell>{employee.userEmail}</TableCell>
											<TableCell>{employee.userPassword}</TableCell>
											<TableCell>
												<IconButton aria-label="Edit" onClick={() => this.handleClickOpen('edit', employee)}>
													<EditIcon />
												</IconButton>
												<IconButton aria-label="Delete" onClick={() => this.handleClickDelOpen(employee)}>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</Fragment>
							</TableBody>
						</Table>
					</div>
				</RctCollapsibleCard>
				<Dialog open={this.state.open} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title" >
					<DialogTitle id="form-dialog-title" style={{marginTop: 20}}>Manage Moderator</DialogTitle>
					<DialogContent style={{width: 400}}>
						<DialogContentText></DialogContentText>
						<TextField	autoFocus id="userName" label="User Name" type="text" fullWidth value={this.state.data.userName} onChange={this.onChange}/>
						<TextField margin="dense" id="userEmail" label="Email" fullWidth value={this.state.data.userEmail} onChange={this.onChange}/>
						<TextField  id="userPassword" label="Password" fullWidth value={this.state.data.userPassword} onChange={this.onChange}/>
					</DialogContent>
					<DialogActions>
					<Button onClick={() => this.handleClose()} color="primary">	Cancel </Button>
					{ this.state.modal_type === 'create' ? <Button onClick={() => this.goCreate()} color="primary"> Create </Button>
						: (this.state.modal_type === 'edit') ? <Button onClick={() => this.goUpdate()} color="primary"> Update </Button>
						: <Button onClick={() => this.handleClose()} color="primary"> Delete </Button>
					}
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.delOpen} onClose={() => this.handleClose()} >
					<DialogTitle id="form-dialog-title" style={{marginTop: 20}}>Manage Moderator</DialogTitle>
					<DialogContent style={{width: 400}}>
						<DialogContentText></DialogContentText>
					</DialogContent>
					<DialogActions>
					<Button onClick={() => this.handleDelClose()} color="primary">	Cancel </Button>
					<Button onClick={() => this.goDelete()} color="primary"> Delete </Button>
					</DialogActions>
				</Dialog>
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { moderator } = state;
    return {
        moderator
    };
}

export default connect(mapStateToProps, null, null, { pure: false })(BasicTable);
