import React from 'react';
import { connect } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { chkSessionActions, menuActions } from "../_actions"

class Feeds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adn_feed: '',
            country_feed: '',
            tld_feed: '',
            anonymous: false,
            session: false,
            
        };
        this.checkSession = this.checkSession.bind(this);

        // this.checkSession();
        this.setMenuState('Feeds');
    }

    onChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    generateCountry = () => {
        window.open('/api/feeds/getFeedsByCountry/' + this.state.country_feed.toLocaleUpperCase(), '_self');
    }

    generateAll = () => {
        window.open('/api/feeds/getFeedsAll', '_self');
    }
    

    checkSession = () => {
        const { dispatch } = this.props;
        dispatch(chkSessionActions.checkSession());
    }

    setMenuState = (type) => {
        const { dispatch } = this.props;
        dispatch(menuActions.setMenu(type));
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.registration.session) {
            this.setState({ session: nextProps.registration.session.payload });
        }
    }

    render() {
        return (
            <div>
                

                <section class="feed">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 feed-lyt">
                                <h1>ThreatHaus Feeds</h1>
                                <p>ThreatHaus Phishing/Malware URLs Data Feed is a real-time data feed of confirmed malicious URLs which can be used as a blocklist/blacklist. The data feed enables companies to protect end users from phishing attacks by blocking or alerting users when they are directed to a phishing site, or receive an email containing a phishing URL.The feed can be used by CERTs and Blue teamers - by integrating into their security products - anti-virus, firewall, mail and proxy services.</p>

                                <div class="alert alert-success" role="alert">
                                    <h4>Caution!</h4> Please do not fetch the feed more often than every 15 minutes..
                                </div>

                            </div>
                            <div class="col-md-6 feed-ryt">

                                <img src="https://cdn.pixabay.com/photo/2017/12/22/08/01/paper-3033204_960_720.jpg" class="img-fluid"/>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="main-timeline">
                                    <div class="timeline">
                                        <div class="timeline-content">
                                            <div class="timeline-icon">
                                                <i class="fa fa-globe"></i>
                                            </div>
                                            <h3 class="title">All Phishing Feeds</h3>
                                            <p class="description">The Country Feed contains all URLs from the ThreatHaus database whose domain name resolve to an IP address associated with a particular geo IP location (country code), no matter whether the URL is active (online) or not.</p>

                                            <p class="description">Click here for a list of Country Top Level Domains (e.g. .ng, .ke, .za, etc)</p>

                                            <button type="button" class="btn btn-success" onClick={this.generateAll}>Generate</button>

                                        </div>
                                    </div>
                                    <div class="timeline">
                                        <div class="timeline-content">
                                            <div class="timeline-icon">
                                                <i class="fa fa-users"></i>
                                            </div>
                                            <h3 class="title">Country Feed</h3>
                                            <p class="description">The Country Feed contains all URLs from the ThreatHaus database whose domain name resolve to an IP address associated with a particular geo IP location (country code), no matter whether the URL is active (online) or not.</p>
                                            <p class="description">Click here for a list of Country Top Level Domains (e.g. .ng, .ke, .za, etc)</p>

                                            <input type="text" placeholder="NG" id="country_feed" onChange={this.onChange}/>
                                            <a className="cursor" onClick={this.generateCountry}>Generate</a>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section class="footer">
                    <div class="container">
                        <div class="col-md-12 footer-head">
                            <h4>Connect with us</h4>
                            <ul>
                                <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                                <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                                <li><i class="fa fa-instagram" aria-hidden="true"></i></li>
                                <li><i class="fa fa-youtube-play" aria-hidden="true"></i></li>
                            </ul>
                            <p>Copyright Threathaus 2019. All Rights Reserved.</p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registration } = state;
    return {
        registration,
    };
}

export default connect(mapStateToProps, null, null, { pure: false })(Feeds);