import React from 'react';
import { connect } from 'react-redux';
import { Grid, TextField, Button, FormControlLabel, Checkbox, Typography, Paper} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { SocialIcon } from 'react-social-icons';
import EnhancedTable from './datatable';
import { submitUrlActions } from '../_actions';
import { allowUrlActions, chkSessionActions, menuActions } from "../_actions"
import { ToastContainer, toast } from 'react-toastify';

const currencies = [
    {
        value: 'malware1',
        label: 'Malware',
    },
    {
        value: 'phishing',
        label: 'Phishing',
    },
];


class Browse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            threat: [''],
            urls: [''],
            tags: '',
            anonymous: false,
            session: false,
        };
        this.getAllMalwares = this.getAllMalwares.bind(this);
        this.checkSession = this.checkSession.bind(this);

        this.checkSession();
        this.setMenuState('Browse');
    }

    addUrl = e => {
        this.setState({
            urls: [...this.state.urls, ''],
            threat: [...this.state.threat, '']
        });
    };

    deleteUrl = e => {
        let index = this.state.urls.length - 1;
        let newAry = [...this.state.urls]
        newAry.splice(index, 1);
        this.setState({
            urls: newAry
        });
    };

    onChangeThreat = index => event => {
        let val = event.target.value;
        this.setState(state => {
            const threat = state.threat.map((item, j) => {
                if (j === index)
                    return val;
                else
                    return item;
            });
            return {
                threat,
            };
        });
    };

    onChangeUrl = index => event => {
        let val = event.target.value;
        this.setState(state => {
            const urls = state.urls.map((item, j) => {
                if (j === index) {
                    return val;
                } else {
                    return item;
                }
            });
            return {
                urls,
            };
        });
    };

    onChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    onChangeAnonymous = event => {
        let val = event.target.checked;
        this.setState({ anonymous: val });
    }

    validateUrl(value) {
        const validateRegEx = new RegExp(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i);
        return validateRegEx.test(value);
    }

    onSubmit = () => {
        for (let row of this.state.urls) {
            if (!this.validateUrl(row)) {
                alert('Missing Url');
                return;
            }
        }
        const { dispatch } = this.props;
        dispatch(submitUrlActions.submitUrl(this.state));
        this.setState({
            urls: [''],
            threat: [''],
            tags: '',
            anonymous: false
        });
    }

    getAllMalwares = () => {
        const { dispatch } = this.props;
        dispatch(allowUrlActions.getAllMalwaresInfo());
    }

    checkSession = () => {
        const { dispatch } = this.props;
        dispatch(chkSessionActions.checkSession());
    }

    goLogin = (type) => {
        window.location.href = `https://threathaus.com/api/authentication/${type}/start`;
    }

    setMenuState = (type) => {
        const {dispatch} = this.props;
        dispatch(menuActions.setMenu(type));
    }

    componentWillMount() {
        this.getAllMalwares();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.registration.session){
            this.setState({session: nextProps.registration.session.payload});
        }
    }

    render() {
        return (
            <div>
                
                {this.props.registration.login.user ? (
                    <div className="browsewidth">
                        <Grid container spacing={1} direction="row" justify="center" alignItems="center" className="rowpan">
                            <Grid item xs={11} style={{ marginTop: 0, marginBottom: 10 }}>
                                <p className ="container-title" style={{ fontSize: 18, lineHeight: 2 }}>
                                    Thank you for helping us keep the internet safe from phishing and malware kits. If you believe you've encountered a page designed to look like another page in an attempt to steal users' personal information, please complete the form below to report the page to the ThreatHaus team.
                                </p>
                            </Grid>
                        </Grid>
                        {this.state.urls.map((option, index) => (
                            <Grid container id={option.value} spacing={2} style={{ marginTop: 10 }} direction="row" justify="center" alignItems="center" key={index}>
                                <Grid item xs={7} >
                                    <TextField placeholder="http://example.tld/mal.exe" onChange={this.onChangeUrl(index)} value={this.state.urls[index]}
                                        label="URL" id="bootstrap-input" style={{ width: "100%" }} variant="outlined" />
                                </Grid>
                                <Grid item xs={3} className="threat">
                                    <TextField
                                        select label="Threat" style={{ width: "100%" }}
                                        onChange={this.onChangeThreat(index)}
                                        SelectProps={{
                                            native: true
                                        }}
                                        variant="outlined"
                                        value={this.state.threat[index]}
                                    >
                                        <option></option>
                                        {currencies.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                {index === this.state.urls.length - 1 ? (
                                    <Grid item xs={1}>
                                        <Fab size="small" color="primary" aria-label="Add" onClick={this.addUrl} >
                                            <AddIcon />
                                        </Fab>
                                        {
                                            index > 0 ? (<Fab size="small" style={{ marginLeft: 10 }} color="secondary" aria-label="Delete" onClick={this.deleteUrl} >
                                                <DeleteIcon />
                                            </Fab>) : null
                                        }
                                    </Grid>) : (<Grid item xs={1}></Grid>)
                                }
                            </Grid>
                        ))
                        }
                        <Grid container style={{ marginTop: 50 }} direction="row" justify="center" alignItems="center">
                            <Grid item xs={11} >
                                <TextField placeholder="tag" id="tags" onChange={this.onChange} value={this.state.tags}
                                    label="Tags" style={{ width: "100%" }} variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={11} >
                                <FormControlLabel value="end"
                                    control={<Checkbox color="primary" onChange={this.onChangeAnonymous} />}
                                    label="Anonymous report?" />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="center" >
                            <Grid item xs={11} >
                                <Button variant="contained" style={{ backgroundColor: "#3bceaf", color: "white", marginTop: 10 }} onClick={this.onSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    ) : (
                        <section class="browse">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-8 browse-lft">
                                    <h1>Actionable. Timely. Prioritized. Threat Intelligence Platform for Africa.</h1>
                                    <p>ThreatHaus is Africa's foremost Threat and Intelligence sharing platform. You can submit new malware/phishing links - and also search the ThreatHaus database.</p>
    
                                </div>
                                <div class="col-md-4 browse-ryt">
                                    <h4>Submit URL</h4>
                                    <p>In order to submit a URL to ThreatHaus, you need to login with</p>
                                    <ul>
                                        <li><i class="fa fa-twitter twitter" aria-hidden="true" network="twitter" bgColor="#00ACED" onClick={() => this.goLogin('twitter')}></i></li>
                                        <li><i class="fa fa-linkedin linkedin" aria-hidden="true" network="linkedin" onClick={() => this.goLogin('linkedin')}></i></li>
                                        <li><i class="fa fa-google-plus google" aria-hidden="true" network="google" onClick={() => this.goLogin('google')}></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    )}
                <section class="database-tble">
                    <div class="container">
                        <div class="col-md-12">
                            <h5>Browse Database</h5>
                        </div>

                        <div class="col-md-12">
                            <EnhancedTable rows={this.props.malwares.data || []} />
                        </div>

                    </div>
                </section>

                <section class="footer">
                    <div class="container">
                        <div class="col-md-12 footer-head">
                            <h4>Connect with us</h4>
                            <ul>
                                <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                                <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                                <li><i class="fa fa-instagram" aria-hidden="true"></i></li>
                                <li><i class="fa fa-youtube-play" aria-hidden="true"></i></li>
                            </ul>
                            <p>Copyright Threathaus 2019. All Rights Reserved.</p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, authentication, malwares, registration } = state;
    const { user } = authentication;
    return {
        user,
        users,
        malwares,
        registration
    };
}

export default connect(mapStateToProps, null, null, { pure: false })(Browse);