import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import AdminTable from './userTable/index';
import {allowUrlActions, menuActions} from "../_actions";


class Users extends React.Component {

    constructor(props){
        super(props);
        this.state = {

        };

        this.getallUrl = this.getallUrl.bind(this);
        // this.setMenuState('Admin');

    }

    getallUrl = () => {
        const { dispatch } = this.props;
        dispatch(allowUrlActions.getAllUrl());
    }

    setMenuState = (type) => {
        const {dispatch} = this.props;
        dispatch(menuActions.setMenu(type));
    }

    componentDidMount() {
        this.getallUrl();
    }
    render() {
        return (
            <div className="w90 margin50">
                <Grid container spacing={1} direction="row" justify="center" alignItems="center" className="rowpan">
                    <Grid item xs={11} style={{marginTop: 50, marginBottom: 20}}>
                        <Typography className="title" variant="h5" color="inherit" noWrap>
                            Manage Moderator Admin
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" >
                    <Grid item xs={11} >
                        <AdminTable />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { virus } = state;
    return {
        virus
    };
}

export default connect(mapStateToProps, null, null, { pure: false })(Users);