
const initialize = {
	rows: []
}

export function malwares(state = initialize, action) {
	switch (action.type) {
		case 'GET_ALL_APPROVAL_MALWARES':
			return {
				...state,
				data: action.payload
			};
		default:
			return state
	}
}