import React from 'react';
import { connect } from 'react-redux';
import { menuActions } from "../_actions";
import ReCAPTCHA from "react-google-recaptcha";
import { notifyActions } from '../_actions';


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            myemail: '',
            recapt:false
        }
        this.setMenuState('Home');
        this.onChange=this.onChange.bind(this);
        this.onChange1=this.onChange1.bind(this);
        this.submit = this.submit.bind(this);
    }
        
    setMenuState = (type) => {
        const { dispatch } = this.props;
        dispatch(menuActions.setMenu(type));
    }
    onChange1(value) {
        console.log("Captcha value:", value);
        this.setState({
            ...this.state,
            recapt:true
        })
      }
    onChange(e) {
        this.setState({[e.target.name]:e.target.value})
      }
    submit = () => {
            if (this.state.myemail=='') {
                alert('Missing Email');
                return;
            }
            if (!this.state.myemail.includes('@') ) {
                alert('Email Format Error');
                return;
            }
            if (!this.state.myemail.includes('.com') ) {
                alert('Email Format Error');
                return;
            }
            if(!this.state.recapt){
                alert('Are you a robot?');
                return;
            }
        const {dispatch} = this.props;
        console.log('here are submit test', this.state);
        dispatch(notifyActions.notify(this.state));
    }

    render() {
        return (
            <div>
                <section class="home">
                  <div class="container">
                      <div class="row">
                          <div class="col-md-6 home-head">
                              <h1>Actionable. Timely. Prioritized.</h1>
                              <p>ThreatHaus™ aggregates millions of threat indicators from a variety of different sources to identify new attacks and empower security teams to quickly contain threats.</p>
                              <a href="#">GET STARTED FREE</a>
                          </div>

                          <div class="col-md-6 home-vid">
                              <a href="https://www.youtube.com/watch?v=P0Fe2viJ508" target="_blank" class="play-button">
                                  <img src="https://www.digitalshadows.com/uploads/2017/09/play-button.png" alt="play-button" class="img-fluid play-buttonn"/>
                                  <img src="images/thumbnail.png" alt="searh-light-video" class="img-fluid"/>
                              </a>
                          </div>

                      </div>
                  </div>
              </section>
              <section class="whattt" id="what">
                <div class="container">
                    <div class="col-md-12 what-head">
                        <h1><span>WELCOME TO BRONTO</span><br />Our Main Features</h1>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="shape shapee">
                                <i class="fa fa-television" aria-hidden="true"></i>
                                <h5>Best Consulting</h5>
                                <p>Lorem Ipsum simply dummy text of the printing typesetting industry simply dummy text typesetting.</p>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="shape shapee">
                                <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
                                <h5>Valuable Ideas</h5>
                                <p>Lorem Ipsum simply dummy text of the printing typesetting industry simply dummy text typesetting.</p>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="shape shapee">
                                <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                                <h5>Budget Friendly</h5>
                                <p>Lorem Ipsum simply dummy text of the printing typesetting industry simply dummy text typesetting.</p>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="shape shapee">
                                <i class="fa fa-headphones" aria-hidden="true"></i>
                                <h5>Online Support 24/7</h5>
                                <p>Lorem Ipsum simply dummy text of the printing typesetting industry simply dummy text typesetting.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section class="Product">
                <div class="overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 pro-head">
                            <p>ThreatHaus is Africa's Threat Intelligence Platform (TIP) that empowers African organizations to aggregate, correlate, and analyze threat data from multiple sources in real time.</p>

                            <h4>Our Product Offerings:</h4>

                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <i class="fa fa-search" aria-hidden="true"></i>
                                <h2>Malicious URLs<br />Database</h2>
                                <p>We detect active/online botnets, malicious websites and phishing pages impacting and originating from Africa. These URLs are analyzed using ThreatHaus' proprietary phishing detection algorith - and updated every hour.</p>
                                <div class="crd-bt">
                                    <a href="browse">BROWSE</a>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">

                            <div class="card">
                                <i class="fa fa-bug" aria-hidden="true"></i>
                                <h2>Malicious IP<br />addresses Feeds</h2>
                                <p>We operate a threat intelligence feed based on 10+ different publicly available lists of known, active, non-sinkholed, suspicious and/or malicious IP addresses. All lists are automatically retrieved and updated every 30minutes.</p>

                                <div class="crd-bt">
                                    <a href="https://threathaus.com/static/feeds/ThreatHausFeed.txt">DOWNLOAD FEED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="Monthly">
                <div class="container">
                    <div class="col-md-12 monthly-head">
                        <h2>ThreatHaus Monthly Briefing</h2>
                    </div>

                    <div class="row">
                        <div class="col-md-6 month-lft">
                            <h4>Subscribe below to our African Cybersecurity monthly summary of:</h4>
                            <ul>
                                <li>(1) Newly discovered attack vectors</li>
                                <li>(2) Vulnerabilities with active new exploits</li>
                                <li>(3) Insightful explanations of how recent attacks worked, and other valuable data.</li>
                            </ul>
                        </div>

                        <div class="col-md-6 month-ryt">
                            <ReCAPTCHA 
                            sitekey="6Lc5bKwUAAAAAEY4ezltfb504xyivK2j-osi1DGN" className="recaptcha"
                            onChange={this.onChange1}
                            />
                            <input type="text" placeholder="notifyme@e-mail.com" name="myemail" value={this.state.myemail} onChange={this.onChange}/>
                            <a href="#" onClick={this.submit}>SUBSCRIBE</a>
                        </div>

                    </div>

                </div>
            </section>

            <section class="footer">
                <div class="container">
                    <div class="col-md-12 footer-head">
                        <h4>Connect with us</h4>
                        <ul>
                            <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                            <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                            <li><i class="fa fa-instagram" aria-hidden="true"></i></li>
                            <li><i class="fa fa-youtube-play" aria-hidden="true"></i></li>
                        </ul>
                        <p>Copyright Threathaus 2019. All Rights Reserved.</p>
                    </div>
                </div>
            </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, authentication, malwares, registration } = state;
    const { user } = authentication;
    return {
        user,
        users,
        malwares,
        registration
    };
}

export default connect(mapStateToProps, null, null, { pure: false })(Home);