import { userService } from '../_services/';
import { API } from '../_helpers';
export const chkSessionActions = {
    checkSession,
};

function checkSession(action){
    return dispatch => {
        let apiEndPoint = API.CHECKSESSION;
        userService.get(apiEndPoint)
            .then( response => {
                if(response.status === 200){
                    dispatch(sucessSession(response.data));
                }else{
                    
                }
            }).catch(e=> {
                
            });
    }
}

export function sucessSession(data) {
    return {
        type: "SESSION_ALLOW_STATE",
        payload: data
    }
}