
const initialize = {
	data1: [],
}

export function moderator(state = initialize, action) {
	switch (action.type) {
		case 'ADD_USER':
			var newData = JSON.parse(JSON.stringify(state.data1));
			newData.push(action.payload);
			return {
				...state,
				data1: newData
			};
		case 'UPDATE_USER':
			var data = action.payload;
			var items = JSON.parse(JSON.stringify(state.data1));
			items[items.findIndex(el => el._id === data._id)] = data;
			return {
				...state,
				data1: items
			};
		case 'DELETE_USER':
			var items = JSON.parse(JSON.stringify(state.data1));
			// items = items.filter(item => item._id === action.payload);
			items.splice(items.findIndex(item => item._id === action.payload), 1);
			return {
				...state,
				data1: items
			};
		case 'GET_ALL_USER':
			return {
				...state,
				data1: action.payload
			};
		default:
			return state
	}
}