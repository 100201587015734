export const menuActions = {
    setMenu,
};

function setMenu(action){
    return dispatch => {
        dispatch(sucessMenu(action));
    }
}

export function sucessMenu(status) {
    return {
        type: "SET_MENU_STATE",
        payload: status
    }
}