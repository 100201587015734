import React from 'react';
import { connect } from 'react-redux';
import { menuActions } from "../_actions"
import { submitActions } from '../_actions';
import ReCAPTCHA from "react-google-recaptcha";


class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            myname: [''],
            myemail: '',
            mysubject: '',
            mymessage: '',
            myphonenumber: '',
            recapt:false
        }
        
        this.onChange=this.onChange.bind(this);
        this.submit = this.submit.bind(this);
        this.onChange1=this.onChange1.bind(this);
        this.setMenuState('Contact');
    }
    onChange1(value) {
        console.log("Captcha value:", value);
        this.setState({
            ...this.state,
            recapt:true
        })
      }

    onChange(e) {
        this.setState({[e.target.name]:e.target.value})
      }
    submit = () => {
            if (this.state.myname==''|| this.state.myemail=='' || this.state.mysubject=='' || this.state.mymessage=='' || this.state.myphonenumber=='') {
                alert('Missing Item');
                return;
            }
            if (!this.state.myemail.includes('@')) {
                alert('Email Format Error');
                return;
            }
            if (!this.state.myemail.includes('.com') ) {
                alert('Email Format Error');
                return;
            }
            if(!this.state.recapt){
                alert('Are you a robot?');
                return;
            }
        const {dispatch} = this.props;
        console.log('here are submit test', this.state);
        dispatch(submitActions.submit(this.state));
    }
        
    setMenuState = (type) => {
        const { dispatch } = this.props;
        dispatch(menuActions.setMenu(type));
    }

    render() {
        return (
            <div>
                <section class="contact">
                    <div class="container">
                        <div class="col-md-12 contact-head">
                            <h1>Contact Us</h1>
                            <p>Tell us a little bit about yourself</p>
                        </div>
                    </div>
                </section>

                <section class="what" id="what">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-3 shape">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                                <h5>Phone</h5>
                                <p>+(002) 0114-9912-318</p>
                                <p>+(002) 0111-7457-354</p>
                            </div>

                            <div class="col-md-3 shape">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                <h5>Email</h5>
                                <p>Hello@example.com
                                    <br />support@yourwebsite.com</p>
                            </div>

                            <div class="col-md-3 shape">
                                <i class="fa fa-location-arrow" aria-hidden="true"></i>
                                <h5>Address</h5>
                                <p>US - Los Angeles 5135 - 4234 In The Center Street Name Here</p>
                            </div>

                            <div class="col-md-3 shape">
                                <i class="fa fa-clock-o" aria-hidden="true"></i>
                                <h5>Online Work</h5>
                                <p>Monday - Fraiday, 9am - 5pm, Saturday & Sunday - CLOSED</p>
                            </div>

                        </div>
                        <div class="col-md-12 brd"></div>

                        <div class="row">

                            <div class="col-md-12 frm-cnt">
                                <p>For URGENT Incident Response service requests, please call <span>+1 855 276 9347</span>, or complete this form and a Services representative will contact you as soon as possible. For all other requests, please complete the form at Request Information.</p>
                            </div>

                            <div class="col-md-5 text-center frm-img">
                                <img src="images/download.jpg" class="img-fluid"/>
                            </div>

                            <div class="col-md-7 contact-frm">
                                <form>
                                    <input type="text" placeholder="Your Name" name="myname" value={this.state.myname} onChange={this.onChange} />
                                    <input type="text" placeholder="Your Email" name="myemail" value={this.state.myemail} onChange={this.onChange} />
                                    <input type="text" placeholder="Your Phone Number" name="myphonenumber" value={this.state.myphonenumber} onChange={this.onChange} />
                                    <input type="text" placeholder="Subject" name="mysubject" value={this.state.mysubject} onChange={this.onChange} />
                                    <textarea placeholder="Your Message Here" rows="5" name="mymessage" value={this.state.mymessage} onChange={this.onChange} ></textarea>
                                </form>
                                <div className="cap_btn">
                                <ReCAPTCHA 
                                sitekey="6Lc5bKwUAAAAAEY4ezltfb504xyivK2j-osi1DGN" className="recaptcha"
                                onChange={this.onChange1}
                                />
                                <a href="#" class="btn-one" onClick={this.submit}>Send Message</a>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

                <section class="footer">
                    <div class="container">
                        <div class="col-md-12 footer-head">
                            <h4>Connect with us</h4>
                            <ul>
                                <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                                <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                                <li><i class="fa fa-instagram" aria-hidden="true"></i></li>
                                <li><i class="fa fa-youtube-play" aria-hidden="true"></i></li>
                            </ul>
                            <p>Copyright Threathaus 2019. All Rights Reserved.</p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, authentication, malwares, registration } = state;
    const { user } = authentication;
    return {
        user,
        users,
        malwares,
        registration
    };
}

export default connect(mapStateToProps, null, null, { pure: false })(Contact);