
const initialize = {
	rows: []
}

export function virus(state = initialize, action) {
	var newRows;
	switch (action.type) {
		case 'SUBMIT_GET_VIRUS':
			return {
				rows: action.payload
			};
		case 'GET_ALLOW_STATE':
			newRows = JSON.parse(JSON.stringify(state.data));
			for (let [index, row] of newRows.entries()) {
				if (row._id === action.payload.success_id) {
					newRows[index].approval = true
					newRows[index].changing = false
					break;
				}
			}
			return {
				data: newRows
			};
		case 'CHANGINE_ALLOW_STATE':
			newRows = JSON.parse(JSON.stringify(state.data));
			for (let [index, row] of newRows.entries()) {
				if (row._id === action.payload) {
					newRows[index].changing = true;
					break;
				}
			}
			return {
				data: newRows
			};
		case 'CHANGINE_ALLOW_FAILE':
			newRows = JSON.parse(JSON.stringify(state.data));
			for (let [index, row] of newRows.entries()) {
				if (row._id === action.payload) {
					newRows[index].approval = false;
					newRows[index].changing = false;
					break;
				}
			}

			return {
				data: newRows
			};
		case 'GET_DELETE_STATE':
			return {
				...state,
				deleted: action.payload
			};
		case 'GET_ALL_URL':
			return {
				...state,
				data: action.payload
			};
		case 'GET_ALL_APPROVAL_MALWARES':
			return {
				...state,
				data: action.payload
			};
		case 'SEND_URLDETAILS':
			return {
				...state,
				data: action.payload
			}
		default:
			return state
	}
}