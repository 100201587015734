import React from 'react';
// import './style/common.less';
import Login from './components/login';
import Browse from './components/browse';
import Feeds from './components/feeds';
import About from './components/about';
import Contact from './components/contact';
import Home from './components/home';
import UrlDetails from './components/UrlDetails';
import Dashboard from './components/Dashboard';
import { history } from './_helpers/history';
import muiTheme from './theme/muiTheme';
import  './theme/style.css';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import PrimarySearchAppBar from './components/navbar';


const dashboard_url = "/1uczlsUen8amKIkWm3054YIAbsbrEvatPcTYtD2Gz2Q9yYTKRJDJxcawsbNWwOf9OWX7nPBNGiK46uDM16lp8jJqllaHXgFz0sve6WPC4puZJXzzUIMQSl53u5yh93H0k22gMHIFW0suMRTwnva5E5WZ1j40cBOmrGs2dYxYNPipu1JHkJBMFnBwwwkxVZHbtY3xMqjn3rCngbUoJj0exsyRMGevtMwNAp8aDhowwOJCOsQlBEpWULEwfGSzgccG";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
}

  render() {
    return (
      <Router history={history}>
        <MuiThemeProvider theme={muiTheme}>
          <div>
            <PrimarySearchAppBar className='app-bar'/>
            <Switch>
              <Route exact path='/' render ={() => (<Redirect to="/home" />) } />
              <Route exact path='/45Ttg^RKiPJ9U2P' component={Login} />
              <Route exact path='/details' component={UrlDetails} />
              <Route exact path='/home' component={Home} />
              <Route exact path='/browse' component={Browse} />
              <Route exact path='/feeds' component={Feeds} />
              <Route exact path='/about' component={About} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path={dashboard_url} component={Dashboard} />
              <Route path='*' render ={() => (<Redirect to="/home" />)} />
            </Switch>
          </div>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;
