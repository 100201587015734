import { userConstants } from '../_constants';

const initialize = {
  id: '',
  myname: '',
  password: '',
  email: '',
  nickname: '',
  loginstate: false
}

export function users(state = initialize, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loginstate: false
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loginstate: true
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loginstate: false
      };
    default:
      return state
  }
}