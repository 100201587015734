import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import DoneTwoToneIcon from "@material-ui/icons/DoneTwoTone";
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import {allowUrlActions} from "../_actions"
import { connect } from 'react-redux';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'dataAdded', align: 'center', width: '*', disablePadding: true, label: 'Dataadded(UTC)' },
  { id: 'url_ip', align: 'center', width: '30%', disablePadding: true, label: 'URL/IP' },
  { id: 'tag', align: 'center', width: '25%', disablePadding: true, label: 'Tags' },
  { id: 'reporter', align: 'center', width: '15%', disablePadding: true, label: 'Reporter' },
  { id: 'threat', align: 'center', width: '15%', disablePadding: true, label: 'Threat' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    
    <TableHead>
      <TableRow style={{backgroundColor:'#f8f8f8'}}>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            xs={2}
            style={{width:row.width, fontSize:18, fontWeight:'bolder'}}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align='center' style = {{width:'15%', fontSize:18, fontWeight:'bolder'}}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.8),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const {onResetFilter} = props;

  const requestResetFilter = event =>{
    onResetFilter(event);
  }

  return (
    <Toolbar
      className={clsx(classes.root, classes.highlight)}
    >
      <div className={classes.title}>
          <Typography variant="h6" id="tableTitle" />
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <Tooltip title="Reset Filter" onClick = {requestResetFilter}>
            <IconButton aria-label="Reset Filter">
              <ThreeSixtyIcon />
            </IconButton>
        </Tooltip>
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    border: 1 ,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progress: {
    // transform: 'translateY(15px)',
    marginTop: 10,
  },
}));

const AdminTable = props => {
  const rows = props.virus.data || [];
 
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('dataAdded');
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterData, setFilterData] = React.useState(null);
  const [reRender, setReRender] = React.useState(false);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }
  
  function extractHostname(url) {
    var element = document.createElement("a");
    element.href = url;
    return element.hostname;
  }

  const customFilter = (key, data) => {

    const aryFilter = rows.filter( row => {
      if(key === 'url_ip'){
        let dbdomain = extractHostname(row[key]);
        let datadomain = extractHostname(data);
        return dbdomain === datadomain;
      }
      else{
        return row[key] === data;
      }
    });
    return aryFilter;
  }

  const filteredRows = filterData ? customFilter(filterData.key, filterData.value) : rows;
  
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

  const onAllow = (id) => event => {
    const {dispatch} = props;
    dispatch(allowUrlActions.allowUrl({id}));
  }

  const onDelete = (id) => event => {
    rows.splice(rows.findIndex(row => row._id === id), 1);
    setReRender(!reRender);
    const {dispatch} = props;
    dispatch(allowUrlActions.deleteUrl({id}));    
  }

  const restrict = (url) => {
    if(url.length > 50){
      return url.substring(0, 47) + "...";
    }else
      return url;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar onResetFilter={() => setFilterData(null)}/>
        <div className={classes.tableWrapper}>
          <Table style={{ width: "100%", tableLayout: "auto" }}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
            />
            <TableBody>
              {stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow style={{cursor:'pointer'}} key={index}>
                      <TableCell align="center" >{row.dataAdded ? row.dataAdded : ''}</TableCell>
                      <TableCell align="center" onClick={() => setFilterData({ key: 'url_ip', value: row.url_ip})} >{restrict(row.url_ip)}</TableCell>
                      <TableCell align="center" padding='none' onClick={() => setFilterData({ key: 'tag', value: row.tag})}>{row.tag}</TableCell>
                      <TableCell align="center" padding='none' onClick={() => setFilterData({ key: 'reporter', value: row.reporter})}>{row.reporter}</TableCell>
                      <TableCell align="center" padding='none' onClick={() => setFilterData({ key: 'threat', value: row.threat})}>{row.threat}</TableCell>
                      <TableCell align="center" padding='none' >
                        <Tooltip title="Allow" >
                            {
                              row.changing ? 
                              <CircularProgress className={classes.progress} size={28}/>
                              : row.approval
                              ? (
                                <IconButton aria-label="Allow" style={{backgroundColor: '#77bfcb', padding: 7}}> <DoneTwoToneIcon /></IconButton>
                            ): (<IconButton aria-label="Allow" onClick = {onAllow(row._id)}><DoneTwoToneIcon /></IconButton>)
                            }
                        </Tooltip>
                        <Tooltip title="Delete" onClick = {onDelete(row._id)}>
                            <IconButton aria-label="Delete">
                              <DeleteForeverOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function mapStateToProps(state) {
  const { virus } = state;
  return {
      virus,
  };
}

export default connect(mapStateToProps, null)(AdminTable);