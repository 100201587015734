import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Paper} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { menuActions } from "../_actions";

class UrlDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.virus.data,
        };
    }

    onChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };
    render() {
        return (
            <div>
                <div className="w90">
                    <section class="detail">
                        <div class="container">
                            <div class="col-md-12 detail-head">
                                <h1>Threat haus Database</h1>
                                <p>Please consider that Threat haus does not differentiate between websites that have been compromised by hackers and such that has been setup by cybercriminals for the sole purpose of serving malware.</p>

                                <h5>Database Entry</h5>
                                <Grid container direction="row" justify="center" alignItems="center" style={{paddingBottom: '10px'}}>
                                    <Grid item xs={12} >
                                        <Paper >
                                            <Table >
                                                <TableBody>
                                                {
                                                this.state.data
                                                ? (
                                                    Object.keys(this.state.data).map(item => (
                                                        (item === '_id' || item=== '__v') ?    
                                                        (null) : 
                                                        (<TableRow>
                                                            <TableCell align="center" style={{borderRight: '1px solid #9b9393'}}>{item}</TableCell>
                                                            <TableCell align="center">{this.state.data[item]}</TableCell>
                                                        </TableRow>)
                                                    ))
                                                ):  <TableRow />
                                                }    
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </section>

                    
                    
                    
                    <section class="footer">
                        <div class="container">
                            <div class="col-md-12 footer-head">
                                <h4>Connect with us</h4>
                                <ul>
                                    <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-instagram" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-youtube-play" aria-hidden="true"></i></li>
                                </ul>
                                <p>Copyright Threathaus 2019. All Rights Reserved.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { virus, registration} = state;

    return {
        virus,
        registration
    };
}

export default connect(mapStateToProps, null, null, { pure: false })(UrlDetails);